import React from 'react'
import Header from "../components/Layout/Header.jsx"

const HomePage = () => {
  return (
    <div>
      <Header/>
    </div>
  )
}

export default HomePage
